.new-landing-wrapper {
  font-family: "Rebyuu-font";
}

.custom-container {
  width: 90%;
  margin: auto;
}

.cta-button {
  background: #ee6b4f;
  padding: 18px 24px;
  outline: none;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
}

.feature-heading {
  font-size: 48px;
  font-weight: 500;
  color: #202124;
  line-height: 1.3;
}

.feature-description {
  color: #707070;
  font-size: 24px;
}

.feature-bullets {
  display: flex;
  align-items: center;
  gap: 16px;
  // border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  padding: 16px 0;

  .feature-bullet-text {
    font-size: 24px;
    color: #000;
  }
}

.feature-atm {
  width: 100%;
}

.banner-container {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  padding: 114px 174px 114px 0;

  .mobile-banner {
    display: none;
  }

  @media only screen and (min-width: 992px) {
    background-image: url("../../../public/images/homepage/home-banner.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media only screen and (max-width: 991px) {
    padding: 6rem 2rem;

    .mobile-banner {
      display: block;
      width: 100%;
    }
  }

  .banner-text-container {
    width: 50%;

    @media only screen and (max-width: 991px) {
      width: 100%;
    }

    .banner-text {
      font-size: 54px;
      font-weight: 700;
      line-height: 1.3;

      @media only screen and (max-width: 991px) {
        font-size: 50px;
      }

      .text-blue {
        color: #3568e7;
      }
    }

    .banner-description {
      font-weight: 500;
      font-size: 18px;
    }

    .banner-cta {
      background: #ee6b4f;
      outline: none;
      border-radius: 8px;
      padding: 16px 32px;
      display: flex;
      flex-direction: column;
      border: none;
      color: #fff;

      .text-one {
        font-size: 16px;
      }

      .text-two {
        font-size: 24px;
        font-weight: 500;
      }
    }
  }
}

.features-section {
  background: #fbf9f9;

  .adjust-padding {
    padding: 72px 0;
  }

  .key-feature-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 18px;

    @media only screen and (max-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .key-feature-card {
      background: #ffffff;
      border: 1px solid #dadce0;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px;
      gap: 8px;

      .key-feature-img {
        width: 48px;
        height: 48px;
      }

      .key-feature-text {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
      }
    }
  }

  .company-logo-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    justify-content: flex-start;
    width: fit-content;
    height: -webkit-fill-available;
    height: fit-content;

    @media only screen and (min-width: 577px) and (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .comapany-logo-card {
      background: #fff;
      max-width: 208px;
      height: fit-content;
      border-radius: 8px;

      @media only screen and (max-width: 768px) {
        max-width: 100%;
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }

  .additional-feature-wrapper {
    display: flex;
    justify-content: center;
    gap: 32px;

    @media only screen and (max-width: 576px) {
      flex-wrap: wrap;
    }

    .additional-feature-card {
      width: 318px;
      height: 364px;
      border: none;
      outline: none;
      background: none;

      @media only screen and (max-width: 576px) {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }
}

.our-offers-wrapper {
  display: grid;
  gap: 16px;

  @media only screen and (min-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .our-offer-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 38px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #dadce0;
    gap: 40px;
    height: 120px;

    @media only screen and (max-width: 991px) {
      min-height: 120px;
      height: fit-content;
      padding: 20px 32px;
      gap: 20px;
    }

    .our-offer-heading {
      font-size: 24px;
      color: #000;
    }
  }
}
