.footer-wrapper {
  padding: 40px 0 40px;
  background-color: #fffdfd;
  border-top: 1px solid #dadce0;
  .logo-wrapper--meta h6 {
    font-size: 17px;
  }
  .logo-wrapper--meta .footer-logo-text {
    font-size: 12px !important;
  }
  &--header {
    // border-bottom: 1px solid $dadce0;
    .icon-logo {
      h6 {
        color: #423f3f;
        font-size: 1.0625rem;
      }
      p {
        font-size: 1rem;
      }
      ul {
        @media (max-width: 768px) {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 4px;
        }
        li {
          display: inline-block;
          a {
            color: #fff;
            display: inline-block;
            margin: 0 5px;
            img {
              @media (max-width: 768px) {
                height: 35px;
                width: 35px;
              }
            }
          }
        }
      }
    }
  }

  &--body {
    .company-details {
      .common {
        @media (max-width: 768px) {
          margin-top: 30px;
        }
        ul {
          li {
            a {
              font-size: 1rem;
            }
            @media (max-width: 768px) {
              font-size: (20 / 16) + rem;
              width: 100%;
            }
            &:not(:last-child) {
              padding-bottom: 15px;
            }
            text-transform: capitalize;
            max-width: 250px;
          }
        }
      }
      h5 {
        @media (max-width: 768px) {
          font-size: (25 / 16) + rem;
        }
        font-size: 1rem;
      }
      .logo {
        @media (max-width: 768px) {
          text-align: center;
          .logo-wrapper {
            @media (max-width: 768px) {
              justify-content: center;
              margin: 0 auto;
            }
          }
        }
        p {
          max-width: 300px;
          line-height: 1.2;
          padding: 16px 0;
          color: #423f3f;
          @media (max-width: 768px) {
            font-size: (18 / 16) + rem;
            width: 100%;
            justify-content: center;
            max-width: 350px;
            margin: auto;
          }
        }
      }
    }
    .qr-code-wrapper {
      @media (min-width: 768px) {
        text-align: right;
      }
      text-align: center;
      img {
        width: 95px;
      }
    }
  }
  &--footer {
    border-top: 1px solid #dadce0;
    padding-top: 24px;
    .footer-links {
      ul {
        @media (max-width: 768px) {
          margin-bottom: 10px;
        }
        li {
          display: inline-block;
          margin-right: 20px;
          a {
            font-size: 1rem;
          }
          @media (max-width: 768px) {
            font-size: (20 / 16) + rem;
            width: 100%;
          }
        }
      }
    }
    p {
      font-size: 1rem;
      @media (max-width: 768px) {
        font-size: (14 / 16) + rem;
      }
    }
  }

  @media (max-width: 768px) {
    .footer-buttons {
      button {
        margin-right: 0 !important;
      }
    }
  }
}
